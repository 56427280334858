<template>
	<div class="user-container">
		<head-top :hiddenList='true'></head-top>
		
		<div class="main">
			<div class="leftMeun"></div>
			<div class="mainContent">
				<div class="categoryBox">
					<span>修改密码</span>
					<div class="box">
						
					</div>
				</div>
				<div class="tableMain">
					<div class="editpwdBox">
						<el-form :model="editpwdForm" hide-required-asterisk  :rules="editpwdRules" ref="editpwdForm" label-width="100px">
							<el-form-item label="原密码" key='oldpwd' prop="oldpwd" >
								<el-input type="password"  v-model="editpwdForm.oldpwd" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label="新密码" key='pass' prop="pass">
								<el-input type="password" v-model="editpwdForm.pass" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label="确认密码" key='checkPass' prop="checkPass">
								<el-input type="password" v-model="editpwdForm.checkPass" autocomplete="off"></el-input>
							</el-form-item>
							
							<el-form-item style="text-align: center;">
								<div class='tipCon'  v-html='pwdTip1'>
									{{pwdTip1}}
								</div>
								<el-button @click="editsubmitForm('editpwdForm')">保存</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Api from "@/server";
	import headTop from "@/components/headTop.vue";
	export default {
		components: {
			headTop,
		},
		data() {
			var validatePass = (rule, value, callback) => {
				if (value === '') {
				  callback(new Error('请输入新密码'));
				} else {
				 if(value){
					 var pwdType=this.pwdType;
					 var maxLenth=this.maxLenth;
					
					if(pwdType.indexOf('1')!=-1){
						if(!/[A-Z]/.test(value)){
						   callback(new Error(this.pwdTip));
						}
					}
					if(pwdType.indexOf('2')!=-1){
						if(!/[a-z]/.test(value)){
						   callback(new Error(this.pwdTip));
						}
					}
					if(pwdType.indexOf('3')!=-1){
						if(!/[0-9]/.test(value)){
						   callback(new Error(this.pwdTip));
						}
					}
					if(pwdType.indexOf('4')!=-1){
						if(!/[^0-9a-zA-Z_]/.test(value)){
						   callback(new Error(this.pwdTip));
						}
					}
					if(value.length<maxLenth||value.length>32){
						  callback(new Error('密码长度为'+this.maxLenth+'到32位'));
					}
				 }
				  callback();
				}
			  };
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
				  callback(new Error('请再次输入密码'));
				} else if (value != this.editpwdForm.pass) {
				  callback(new Error('两次输入密码不一致!'));
				} else {
				  callback();
				}
			};
			var checkoldPwd=(rule, value, callback) => {
				if (value === '') {
				  callback(new Error('请输入密码'));
				  }
				else {
				  callback();
				}
			};
			
      return {
        imgUrl: Api.imgUrl,
				editpwdForm: {
				          pass: '',
				          checkPass: '',
				          oldpwd: ''
				        },
				editpwdRules: {
				  pass: [
					{ validator: validatePass, trigger: 'blur' }
				  ],
				  checkPass: [
					{ validator: validatePass2, trigger: 'blur' }
				  ],
				  oldpwd: [
					{ validator: checkoldPwd, trigger: 'blur' }
				  ]
				},
				maxLenth:0,//密码最大长度
				pwdTip:'',
				pwdTip1:'',
				pwdType:'',
			}
		},
		mounted() {
			this.getPwdInfo();
		},
		methods:{
			getPwdInfo(){//密码强度规则
				Api.ajaxRequest('pwd/get/config',{}).then(res => {
					let {code,data} = res;
					let _this=this;
					if (code == '200') {
						this.maxLenth=parseInt(data.maxLenth);
						
						let pwdType=data.pwdType;
						// var zz='[0-9A-Z]';
						var pwdtip='密码须包含';
						var pwdtip1='<p>提示：</p><p>密码须'+data.maxLenth+'到32位，同时包含';
						var tip1='';
						if(pwdType.indexOf('1')!=-1){
							pwdtip=pwdtip+'大写字母'
							pwdtip1=pwdtip1+'大写字母';
							tip1+='<p>大写字母：ABCDEFGHIJKLMNOPQRSTUVWXYZ</p>';
						}
						if(pwdType.indexOf('2')!=-1){
							pwdtip=pwdtip+'小写字母'
							pwdtip1=pwdtip1+'小写字母';
							tip1+='<p>小写字母：abcdefghijklmnopqrstuvwxyz</p>';
						}
						if(pwdType.indexOf('3')!=-1){
							pwdtip=pwdtip+'数字'
							pwdtip1=pwdtip1+'数字';
							tip1+='<p>数字：0123456789</p>';
						}
						if(pwdType.indexOf('4')!=-1){
							pwdtip=pwdtip+'特殊符号'
							pwdtip1=pwdtip1+'特殊符号';
							tip1+='<p>特殊符号：（^ $ . * + - ? = ! : | / ( ) [ ] { } 1.“[ ]”）</p>';
						}
						pwdtip1+='</p>'+tip1;
						this.pwdTip=pwdtip;
						this.pwdTip1=pwdtip1;
						this.pwdType=pwdType;
					}
				});
			},
			
      //密码修改
			editsubmitForm(editpwdForm) {
      	let _this=this;
			  this.$refs[editpwdForm].validate((valid) => {
			    if (valid) {
					//console.log(editpwdForm)
					var params={"newPasswd":this.editpwdForm.checkPass,"oldPasswd":this.editpwdForm.oldpwd};
					Api.ajaxRequest('user/modify/password',params).then(res => {
						let {code,data} = res;
						if (code == '200') {
							 this.$message({
							  type: 'success',
							  message: '修改成功'
							});
							setTimeout(function(){
                _this.$parent.needChangePsd = 'N'
                _this.$cookies.set('token','')
							},2000);
						}
						else{
							if(code=='503'){
								_this.$cookies.set('token','')
								_this.$router.push({path: '/'});
							}
							else{
								this.$message({
								  type: 'warning',
								  message:res.description
								});
							}
						}
						
					});
			    } else {
			     
			      return false;
			    }
			  });
			},
		}
	}
</script>

<style scoped lang="less">
	.user-container{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
		.tabBox{width:100%;height:50px;border-bottom:1px solid #f5f7fe;display: flex;}
		.tabBox .tabItem{height:100%;cursor: pointer; margin-right:40px;font-size:16px;color:#666666;}
		.tabBox .tabItem.active{border-bottom:2px solid #b99765;color:#b99765;}
		.leftMeun{width: 192px;height: 100%;}
		.mainCon{width:400px;height:300px;margin-top:20px; border:1px solid #dde7ff;border-radius: 10px;box-sizing: border-box; padding:30px 30px 30px 10px;}
		.editpwdBox{width:600px;height:450px;margin-top:20px; border:1px solid #dde7ff;border-radius: 10px;box-sizing: border-box; padding:30px 30px 30px 10px;}
		.ewmBox{width:550px;height:300px;min-width: 550px; margin-top:20px; border:1px solid #dde7ff;border-radius: 10px;box-sizing: border-box; display: flex;align-items: center;justify-content: center;}
		.ewmBox .ewmItem{text-align: center;margin:0px 20px;}
    .ewmBox .ewmItem img{ width: 200px; height:200px;}
		.ewmBox .ewmItem .codeName{margin-top:10px;color:#121212;font-weight: bold;font-size:14px;}
		.ewmBox .ewmItem .bindbtn{width:140px;margin-top:20px;}
		.ewmBox .ewmItem .userInfo{height:125px}
		.ewmBox .ewmItem .userInfo>p{font-size:16px;margin-top:5px;}
		.ewmBox .ewmItem .userInfo>.userIcon{width:100px;height:100px;margin:0 auto;border-radius: 100px;overflow: hidden;}
		.ewmBox .ewmItem .userInfo>.userIcon>img{width:100%;height: 100%;}
		.tipCon{color:#666;line-height: 20px;margin:0px 0px 20px 0px;text-align: left;}
	}
	
	
	/deep/ .el-button{
		border: solid 1px #b99765 !important;
		color: #fff !important;
		background-color: #b99765 !important;
	}
</style>
