<template>
  <div class="fontCode" :class="{ show_: show }">
    <div class="outside" ref="outside">
      <div class="tips">请依次点击：<p class="textImg" :style="'background-image: url('+ imgs +')'"></p></div>
      <div class="display-area" :style="'background-image: url('+ imgs +')'" @click="addNum">
        <span v-for="(item,index) in choseOrder" :key="index" :style="{'left': item.split(',')[0]+'px', 'top': item.split(',')[1]+'px'}" class="choseOrder" @click.stop="removeNum(index)">{{index + 1}}</span>
      </div>

      <div class="msg" v-show="!isSucceed">
        <img @click="changeAll" src="../assets/images/reset.png" alt="" />
        <button @click="enter">确认</button>
      </div>

      <div v-show="isSucceed" class="tip">
        <div class="tip-content">验证成功！</div>
      </div>
      <div v-show="falseTip" class="tip" @click="changeAll">
        <div class="tip-content">验证失败，请<i style="color: red;font-weight: normal;">重新验证</i></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      show: { type: Boolean, default: false },
      imgs: '',
      loginStatus: '',
    },
    data() {
      return {
        choseOrder: [], //用户选择的顺序
        isSucceed: false, //用户验证成功
        falseTip: false, //用户验证失败
      }
    },
    watch: {
      loginStatus(newVal){
        if(newVal == 'Y'){
          this.isSucceed = true;
        }else if(newVal == 'N'){
          this.falseTip = true;
        }else{
          this.isSucceed = false;
          this.falseTip = false;
        }
      }
    },
    methods: {
      // 添加点
      addNum(e){
        if(this.choseOrder.length < 4){
          this.choseOrder.push(e.offsetX+','+e.offsetY);
        }
      },
      // 移除点
      removeNum(i){
        this.choseOrder.splice(i);
      },
      
      // 确认
      enter(){
        this.$emit('success',this.choseOrder);
      },
      
      //换一张    重新部署
      changeAll() {
        this.isSucceed = false;
        this.falseTip = false;
        this.choseOrder = [];
        this.$emit('again');
      },
    },
    watch: {
      show(newVal){
        if(newVal){
          this.choseOrder = [];
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .fontCode{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms;
    &.show_ {
      opacity: 1;
      pointer-events: auto;
    }
  }
  /* 最外侧轮廓 */
  .outside {
    width: 400PX;
    // height: 380px;
    border: 1px solid black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .tips{
    font-size: 16px;
    color: #000;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  .tips .textImg{
    width: 130PX;
    height: 24PX;
    background-size: 350PX 350PX;
    background-position: left bottom;
    background-repeat: no-repeat;
  }

  /**上方图片展示区域 */
  .display-area {
    width: 350PX;
    height: 325PX;
    position: relative;
    background-size: 350PX 350PX;
    background-position: center top;
    background-repeat: no-repeat;
    cursor: pointer;
    .choseOrder{
      width: 24PX;
      height: 24PX;
      position: absolute;
      text-align: center;
      line-height: 20PX;
      color: #fff;
      border-radius: 50%;
      border: 2px solid #fff;
      background-color: #0e6be7;
      transform: translate(-50%,-50%);
    }
  }

  /**底部提示用户操作区 换一张按钮放置区 */
  .msg {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    img{
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
    button {
      background-color: #fff;
      color: #0e6be7;
      border: 1px solid #0e6be7;
      text-align: center;
      width: 100px;
      height: 30px;
      text-transform: uppercase;
      box-shadow: 0 0 20px #eee;
      border-radius: 4px;
      display: block;
      transition: all .2s linear;
      &:hover{
        background-color: rgba(14, 107, 231, .7);
        border-color: rgba(14, 107, 231, .7);
        color: #fff;
      }
    }
  }

  /**判断用户验证结果的提示 */
  .tip {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 127, 127, 0.8);
    font-size: large;
    z-index: 10;
    color: #fff;
    cursor: pointer;
  }

  .tip-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: bolder;
    z-index: 15;
  }
</style>