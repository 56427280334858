import { render, staticRenderFns } from "./headTop.vue?vue&type=template&id=edef51a0&scoped=true"
import script from "./headTop.vue?vue&type=script&lang=js"
export * from "./headTop.vue?vue&type=script&lang=js"
import style0 from "./headTop.vue?vue&type=style&index=0&id=edef51a0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edef51a0",
  null
  
)

export default component.exports