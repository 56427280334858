<template>
  <!-- 本体部分 -->
  <div :class="['vue-puzzle-vcode', { show_: show }]"
       @mousedown="onCloseMouseDown"
       @mouseup="onCloseMouseUp"
       @touchstart="onCloseMouseDown"
       @touchend="onCloseMouseUp">
    <div class="vue-auth-box_" @mousedown.stop @touchstart.stop>
      <div class="auth-body_" :style="`height: ${canvasHeight}px`">
        <!-- 主图，有缺口 -->
        <img :src="imgs" :style="`width:${canvasWidth}PX;height:${canvasHeight}PX`" alt="" />
        <!-- 小图 -->
        <img ref="canvas2" class="auth-canvas2_" :src="puzzle" :style=" `width:${sliderSize}PX;height:${sliderSize}PX;transform:translate(${styleWidth - sliderBaseSize - (puzzleBaseSize - sliderBaseSize) * ((styleWidth - sliderBaseSize) / (canvasWidth - sliderBaseSize))}PX,${locationY}PX)` " alt="" />
        <div :class="['loading-box_', { hide_: !loading }]">
          <div class="loading-gif_">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div :class="['info-box_', { show: infoBoxShow }, { fail: infoBoxFail }]">
          {{ infoText }}
        </div>
        <div :class="['flash_', { show: isSuccess }]"
             :style="
            `transform: translateX(${
              isSuccess
                ? `${canvasWidth + canvasHeight * 0.578}PX`
                : `-${canvasHeight * 0.578}PX`
            }) skew(-30deg, 0);`
          "></div>
        <img class="reset_" @click="reset" :src="resetSvg" />
      </div>
      <div class="auth-control_">
        <div class="range-box" :style="`height:${sliderBaseSize}PX`">
          <div class="range-text">{{ sliderText }}</div>
          <div class="range-slider" ref="range-slider" :style="`width:${styleWidth}PX`">
            <div :class="['range-btn', { isDown: mouseDown }]"
                 :style="`width:${sliderBaseSize}PX`"
                 @mousedown="onRangeMouseDown($event)"
                 @touchstart="onRangeMouseDown($event)">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import resetSvg from "../assets/images/reset.png";
export default {
  props: {
    canvasWidth: { type: Number, default: 310 }, // 主canvas的宽
    canvasHeight: { type: Number, default: 160 }, // 主canvas的高
    // 是否出现，由父级控制
    show: { type: Boolean, default: false },
    puzzleScale: { type: Number, default: 1 }, // 拼图块的大小缩放比例
    sliderSize: { type: Number, default: 50 }, // 滑块的大小
    range: { type: Number, default: 10 }, // 允许的偏差值
    // 所有的背景图片
    imgs: {type: String},
    puzzle: {type: String},
    locationY: {type: String},
    loginStatus: {type: String},
    failText: {
      type: String,
      default: "验证失败，请重试"
    },
    sliderText: {
      type: String,
      default: "拖动滑块完成拼图"
    }
  },

  data() {
    return {
      mouseDown: false, // 鼠标是否在按钮上按下
      startWidth: 50, // 鼠标点下去时父级的width
      startX: 0, // 鼠标按下时的X
      newX: 0, // 鼠标当前的偏移X
      pinX: 0, // 拼图的起始X
      pinY: 0, // 拼图的起始Y
      loading: false, // 是否正在加在中，主要是等图片onload
      isCanSlide: false, // 是否可以拉动滑动条
      error: false, // 图片加在失败会出现这个，提示用户手动刷新
      infoBoxShow: false, // 提示信息是否出现
      infoText: "", // 提示等信息
      infoBoxFail: false, // 是否验证失败
      closeDown: false, // 为了解决Mac上的click BUG
      isSuccess: false, // 验证成功
      imgIndex: -1, // 用于自定义图片时不会随机到重复的图片
      isSubmting: false, // 是否正在判定，主要用于判定中不能点击重置按钮
      resetSvg,
    };
  },

  /** 生命周期 **/
  mounted() {
    document.body.appendChild(this.$el);
    document.addEventListener("mousemove", this.onRangeMouseMove, false);
    document.addEventListener("mouseup", this.onRangeMouseUp, false);

    document.addEventListener("touchmove", this.onRangeMouseMove, {
      passive: false
    });
    document.addEventListener("touchend", this.onRangeMouseUp, false);
    if (this.show) {
      document.body.classList.add("vue-puzzle-overflow");
      this.resetState();
    }

  },
  beforeDestroy() {
    document.body.removeChild(this.$el);
    document.removeEventListener("mousemove", this.onRangeMouseMove, false);
    document.removeEventListener("mouseup", this.onRangeMouseUp, false);

    document.removeEventListener("touchmove", this.onRangeMouseMove, {
      passive: false
    });
    document.removeEventListener("touchend", this.onRangeMouseUp, false);
  },

  /** 监听 **/
  watch: {
    show(newV) {
      // 每次出现都应该重新初始化
      if (newV) {
        document.body.classList.add("vue-puzzle-overflow");
        this.resetState();
      } else {
        this.isSubmting = false;
        this.isSuccess = false;
        this.infoBoxShow = false;
        document.body.classList.remove("vue-puzzle-overflow");
      }
    },
    loginStatus(newVal){
      if(newVal == 'Y'){
        this.isSuccess = true;
        this.infoBoxShow = true;
        this.infoText = '验证成功';
      }else if(newVal == 'N'){
        this.isSuccess = false;
        this.infoBoxShow = true;
        this.infoBoxFail = true;
        this.infoText = '验证失败';
        setTimeout(()=>{
          this.resetState();
          this.$emit('again');
        },800)
      }else{
        this.infoBoxShow = false;
        this.infoBoxFail = false;
      }
    }
  },

  /** 计算属性 **/
  computed: {
    // styleWidth是底部用户操作的滑块的父级，就是轨道在鼠标的作用下应该具有的宽度
    styleWidth() {
      const w = this.startWidth + this.newX - this.startX;
      return w < this.sliderBaseSize
        ? this.sliderBaseSize
        : w > this.canvasWidth
        ? this.canvasWidth
        : w;
    },
    // 图中拼图块的60 * 用户设定的缩放比例计算之后的值 0.2~2
    puzzleBaseSize() {
      return Math.round(
        Math.max(Math.min(this.puzzleScale, 2), 0.2) * 52.5 + 6
      );
    },
    // 处理一下sliderSize，弄成整数，以免计算有偏差
    sliderBaseSize() {
      return Math.max(
        Math.min(
          Math.round(this.sliderSize),
          Math.round(this.canvasWidth * 0.5)
        ),
        10
      );
    }
  },

  /** 方法 **/
  methods: {
    // 关闭
    onClose() {
      if (!this.mouseDown && !this.isSubmting) {
        this.$emit("close");
      }
    },
    onCloseMouseDown() {
      this.closeDown = true;
    },
    onCloseMouseUp() {
      if (this.closeDown) {
        this.onClose();
      }
      this.closeDown = false;
    },
    // 鼠标按下准备拖动
    onRangeMouseDown(e) {
      if (this.isCanSlide) {
        this.mouseDown = true;
        this.startWidth = this.$refs["range-slider"].clientWidth;
        this.newX = e.clientX || e.changedTouches[0].clientX;
        this.startX = e.clientX || e.changedTouches[0].clientX;
      }
    },
    // 鼠标移动
    onRangeMouseMove(e) {
      if (this.mouseDown) {
        e.preventDefault();
        this.newX = e.clientX || e.changedTouches[0].clientX;
      }
    },
    // 鼠标抬起
    onRangeMouseUp(e) {
      if (this.mouseDown) {
        this.mouseDown = false;
        this.submit();
      }
    },
    
    // 开始判定
    submit() {
      this.$emit('success',this.$refs["range-slider"].clientWidth- this.sliderSize);
    },
    // 重置 - 重新设置初始状态
    resetState() {
      this.loading = true;
      this.infoBoxFail = false;
      this.infoBoxShow = false;
      this.isCanSlide = true;
      this.isSuccess = false;
      this.startWidth = this.sliderBaseSize; // 鼠标点下去时父级的width
      this.startX = 0; // 鼠标按下时的X
      this.newX = 0; // 鼠标当前的偏移X
      setTimeout(()=>{
        this.loading = false;
      },300)
    },

    // 重置
    reset() {
      if(this.isSubmting){
        return;
      }
      this.$emit('again');
      this.resetState();
      this.isCanSlide = true;
    }
  }
};
</script>
<style lang="less">
.vue-puzzle-vcode {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms;
  &.show_ {
    opacity: 1;
    pointer-events: auto;
  }
}
.vue-auth-box_ {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: #fff;
  user-select: none;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  .auth-body_ {
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    .loading-box_ {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 20;
      opacity: 1;
      transition: opacity 200ms;
      display: flex;
      align-items: center;
      justify-content: center;
      &.hide_ {
        opacity: 0;
        pointer-events: none;
        .loading-gif_ {
          span {
            animation-play-state: paused;
          }
        }
      }
      .loading-gif_ {
        flex: none;
        height: 5px;
        line-height: 0;
        @keyframes load {
          0% {
            opacity: 1;
            transform: scale(1.3);
          }
          100% {
            opacity: 0.2;
            transform: scale(0.3);
          }
        }
        span {
          display: inline-block;
          width: 5px;
          height: 100%;
          margin-left: 2px;
          border-radius: 50%;
          background-color: #888;
          animation: load 1.04s ease infinite;
          &:nth-child(1) {
            margin-left: 0;
          }
          &:nth-child(2) {
            animation-delay: 0.13s;
          }
          &:nth-child(3) {
            animation-delay: 0.26s;
          }
          &:nth-child(4) {
            animation-delay: 0.39s;
          }
          &:nth-child(5) {
            animation-delay: 0.52s;
          }
        }
      }
    }
    .info-box_ {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 24px;
      line-height: 24px;
      text-align: center;
      overflow: hidden;
      font-size: 13px;
      background-color: #83ce3f;
      opacity: 0;
      transform: translateY(24px);
      transition: all 200ms;
      color: #fff;
      z-index: 10;
      &.show {
        opacity: 0.95;
        transform: translateY(0);
      }
      &.fail {
        background-color: #ce594b;
      }
    }
    .auth-canvas2_ {
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 100%;
      z-index: 2;
    }
    .auth-canvas3_ {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 600ms;
      z-index: 3;
      &.show {
        opacity: 1;
      }
    }
    .flash_ {
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.1);
      z-index: 3;
      &.show {
        transition: transform 600ms;
      }
    }
    .reset_ {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 35px;
      height: auto;
      z-index: 12;
      cursor: pointer;
      transition: transform 200ms;
      transform: rotate(0deg);
      &:hover {
        transform: rotate(-90deg);
      }
    }
  }
  .auth-control_ {
    .range-box {
      position: relative;
      width: 100%;
      background-color: #eef1f8;
      margin-top: 20px;
      border-radius: 3px;
      box-shadow: 0 0 8px rgba(240, 240, 240, 0.6) inset;
      .range-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
        color: #b7bcd1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        width: 100%;
      }
      .range-slider {
        position: absolute;
        height: 100%;
        width: 50px;
        background-color: rgba(106, 160, 255, 0.8);
        border-radius: 3px;
        .range-btn {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          right: 0;
          width: 50px;
          height: 100%;
          background-color: #fff;
          border-radius: 3px;
          box-shadow: 0 0 4px #ccc;
          cursor: pointer;
          & > div {
            width: 0;
            height: 40%;

            transition: all 200ms;
            &:nth-child(2) {
              margin: 0 4px;
            }
            border: solid 1px #6aa0ff;
          }
          &:hover,
          &.isDown {
            & > div:first-child {
              border: solid 4px transparent;
              height: 0;
              border-right-color: #6aa0ff;
            }
            & > div:nth-child(2) {
              border-width: 3px;
              height: 0;
              border-radius: 3px;
              margin: 0 6px;
              border-right-color: #6aa0ff;
            }
            & > div:nth-child(3) {
              border: solid 4px transparent;
              height: 0;
              border-left-color: #6aa0ff;
            }
          }
        }
      }
    }
  }
}
.vue-puzzle-overflow {
  overflow: hidden !important;
}
</style>