<template>
  <div class="container">
    <div class="FixedImg logo">
      <img src="../assets/images/logo_title.png"/>
      <div class="logoTitle">
<!--        <div>5G场景“旅游+”智慧化服务技术研发与应用</div>-->
        <p>课题 1：5G 场景“旅游+”沉浸式体验技术研发与应用</p>
        <p>课题 2：5G 场景 “旅游+” 一体化数据中台研发与应用</p>
      </div>
    </div>
    <!-- <div class="FixedImg poetry">
      <img src="../assets/images/login/poetry.png" />
    </div>
    <div class="FixedImg sun">
      <img src="../assets/images/login/icon-sun.png" />
    </div> -->
    <div class="loginBox">
      <!-- <img class="icon" v-if="isShow" @click="logintab('zzd')" src="../assets/images/login/icon-20.png" alt="" /> -->
      <!-- <img class="icon" v-else @click="logintab('zhh')" src="../assets/images/login/icon-21.png" alt="" /> -->
      <div class="login-form" v-if="logintabVal == 'zhh'">
        <p class="title">欢迎登录</p>
        <ul>
          <li>
            <img src="../assets/images/login/icon-7.png" alt="">
            <input type="text" v-model="user" placeholder="请输入账号" @keyup.enter="beforeLogin"/>
          </li>
          <li>
            <img src="../assets/images/login/icon-8.png" alt="">
            <input type="password" v-model="password" placeholder="请输入密码" @keyup.enter="beforeLogin"/>
          </li>
          <li class="li-nth3" v-if="verifyType == 'simple' || verifyType == 'calculate'">
            <img src="../assets/images/login/icon-9.png" alt="">
            <input type="text" v-model="code" placeholder="请输入右侧验证码" @keyup.enter="beforeLogin"/>
            <img class="ewm-left" src="../assets/images/login/icon-10.png" @click="genVerifyCode"/>
            <img class="ewm" :src="assetsimages" @click="genVerifyCode"/>
          </li>
        </ul>
        <div class="loginBtn" @click="beforeLogin">登 录</div>
      </div>
      <div class="login-form" v-if="logintabVal == 'zzd'">
        <p class="title">浙政钉扫码登录</p>
        <div>
          <iframe id="Iframe" scrolling="no"
                  src="https://login-pro.ding.zj.gov.cn/qrlogin/webAppLogin.htm?APP_NAME=syxzjlw_dingoa&protocolKey=d8cc14d6-affb-4c91-b1b0-faeea7fc2022&protocol=oauth2&BACK_URL=http://zjlw.songyang.gov.cn:8090/uc/index.html?syscode=admin/index&embedMode=true&scope=get_user_info&state="></iframe>
        </div>
      </div>
      <!-- <div v-if="logintabVal == 'wx'">
        <p class="title">微信扫码登录</p>
        <img class="wxLogin" :src="imgUrl + 'appfile' + qrCode" />
      </div> -->
    </div>

    <div class="FixedImg loginTips" v-if="loginblock" @click="loginyab">
      <span>{{ loginco }}</span>
      <p>×</p>
    </div>
    <!-- 技术支持 -->
    <div class="jszc">
      版权所有：广西师范大学、浙江天迈文化科技有限公司、龙胜各族自治县全域旅游投资开发有限公司<span>|</span><a target="_blank" href="https://beian.miit.gov.cn">ICP证：浙ICP备16041757号-1</a>
    </div>

    <components :is="componentsName(verifyType)" :show="ShowVerifymask" :imgs="assetsimages" :puzzle="puzzle"
                :locationY="locationY" :loginStatus="loginStatus" @success="Login" @again="genVerifyCode"></components>

    <changePsd v-if="needChangePsd === 'Y'"></changePsd>
  </div>
</template>
<script>
import eventBus from "@/server/eventBus";
import Api from "@/server";
import code_puzzle from "../components/codePuzzle.vue"
import code_picture from "../components/codePicture.vue";
import {sm2} from 'sm-crypto';

const public_key = '0441f8f5058ecdb018101736b70a66ab2e912ef6510cce417f44428d60cf3d0e57d87babd6ac15882c18fb0223b449cd5b03be6266e327db0d637489262e358e80';
import changePsd from '@/components/changePsd'

export default {
  components: {
    code_puzzle,
    code_picture,
    changePsd
  },
  name: "login",
  data() {
    let qrTime = null;
    return {
      imgUrl: Api.imgUrl,
      auth_code: '',
      // readonly: true,

      isSaveLoginInfo: true,

      // 登录结果提示
      loginblock: false,
      loginco: "",

      logintabVal: "zhh", //	登入方式

      // 账号密码登录
      user: "",
      password: "",
      code: "",
      assetsimages: "", //	二维码图片
      puzzle: '', //  拼图小图片
      locationY: '',  //  Y轴偏移量
      keepPsw: true, // 记住密码
      verifyType: '', //  二维码类型
      sn: '',
      loginStatus: '',
      ShowVerifymask: false, //  二维码类型弹窗

      urls: "",
      systemName: "",
      wsInit: null,

      qrCode: "", //	微信登陆二维码code
      isShow: 'zhh',
      locationHref: '',	//	需要重新跳转页面
      needChangePsd: 'N',  //  需要重置密码
    };
  },
  computed: {
    componentsName() {
      return function (val) {
        let name = '';
        switch (val) {
          case 'picture':
          case 'puzzle':
            name = 'code_' + val;
            break;
          default:
            name = '';
            break;
        }
        return name;
      }
    }
  },
  methods: {
    savePass(is) {
      this.isSaveLoginInfo = !is
    },
    loginyab() {
      this.logintabVal = "zhh";
      this.loginblock = false;
    },

    // 登陆方式切换
    logintab(val) {
      this.logintabVal = val;
      clearInterval(this.qrTime);
      this.isShow = val;
    },

    // 账号密码登录
    beforeLogin() {
      this.loginblock = false;
      if (this.user == "" || !this.user) {
        this.loginblock = true;
        this.loginco = "请输入账号";
        return;
      }
      if (this.password == "" || !this.password) {
        this.loginblock = true;
        this.loginco = "请输入密码";
        return;
      }
      if ((this.code == "" || !this.code) && this.verifyType == 'simple') {
        this.loginblock = true;
        this.loginco = "请输入验证码";
        return;
      }
      let HasUrls = this.urls ?? 'N';
      if (HasUrls == 'N') {
        this.urls = 'uc';
      }
      if (this.verifyType !== 'simple' && this.verifyType !== 'calculate') {
        this.genVerifyCode();
      }
      setTimeout(() => {
        if (this.verifyType !== 'simple' && this.verifyType !== 'calculate') {
          // 没有输入验证码 进行校验
          this.ShowVerifymask = true;
          return;
        }
        this.Login();
      }, 200)
    },
    Login(code) {
      let params = {
        pin: this.user,
        passcode: sm2.doEncrypt(this.password, public_key, 1),
        type: 'account',
        verifyCodeVo: {
          type: this.verifyType,
        },
        syscode: this.urls,
      };
      switch (this.verifyType) {
        case 'simple':  // 图文
          params.verifyCodeVo['code'] = this.code;
          break;
        case 'calculate': // 计算
          params.verifyCodeVo['code'] = this.code;
          params.verifyCodeVo['sn'] = this.sn;
          break;
        case 'picture': // 动态图文
          params.verifyCodeVo['sites'] = code;
          params.verifyCodeVo['sn'] = this.sn;
          break;
        case 'puzzle':
          // 移动拼图
          params.verifyCodeVo['locationX'] = code;
          params.verifyCodeVo['sn'] = this.sn;
          break;
        default:
          break;
      }
      Api.ajaxRequest("sslogin", params).then((res) => {
        if (res.code == "200") {
          this.loginStatus = 'Y';
          setTimeout(() => {
            this.ShowVerifymask = false;
          }, 1000)
          this.$cookies.set("token", res.token);
          this.$cookies.set("uid", res.uid);
          this.GetStyleSet(res.backurl);
          this.needChangePsd = res?.others?.resetPwd ?? 'N';
          if (this.isSaveLoginInfo == true) {
            this.$cookies.set("psd", this.password);
            this.$cookies.set("name", this.user);
          } else {
            this.$cookies.set("psd", '');
            this.$cookies.set("name", '');
          }
          //this.WebSocketTest();

        } else {
          if (res.detail == '验证码错误' && this.verifyType !== 'simple' && this.verifyType !== 'calculate') {
            this.loginStatus = 'N';
          } else {
            this.ShowVerifymask = false;
            this.loginStatus = '';
            this.loginblock = true;
            this.loginco = res.detail + "，请重新输入";
            this.genVerifyCode();
          }
        }
      });
    },

    // 权限模块
    permission(val) {
      let params = {};
      Api.ajaxRequests("user/query/module", params).then((res) => {
        let {
          code,
          data
        } = res;
        if (code == "200") {
          if (data != "" && data != null) {
            let arr = [];
            let a = val.lastIndexOf("=") + 1;
            let token = val.slice(a);
            let list = data;
            list.forEach((item, index) => {
              if (item.url != "" && item.url != null) {
                arr.push(item.url);
              }
            });
            window.location.href =
                "https://hdgy.zswl0580.cn/zhzsfx/index.html#" +
                arr[0] +
                "?token=" +
                token;
          } else {
            // window.location.href="https://syhouse.k95.cn/index.html#/"
          }

          // list.forEach((item,index)=>{
          // 	arr.push({
          // 		id: item.id,
          // 		pathUrl: item.url,
          // 		name: item.name,
          // 	})
          // })
          // this.menuArr=arr
          // this.$cookies.set('uc_MeunInd', data[0].id);
        } else if (code == "503") {
          // window.location.href="https://syhouse.k95.cn/index.html#/"
        } else {
          // window.location.href="https://syhouse.k95.cn/index.html#/"
        }
      });
    },

    // 微信扫码登录
    wxLogin() {
      Api.ajaxRequest("wx/get/qrcode", {
        authType: "login"
      }).then((res) => {
        let {
          code,
          data
        } = res;
        if (code == 200) {
          this.qrCode = data;
          let authKey = Api.getUrlParams(data, "authKey");
          this.qrTime = setInterval(() => {
            let params = {
              passcode: authKey,
              type: "wx",
              syscode: this.urls,
            };
            Api.ajaxRequest("sslogin", params).then((res) => {
              if (res.code == "200") {
                this.$cookies.set("token", res.token);
                this.$cookies.set("uid", res.uid);
                this.needChangePsd = res?.others?.resetPwd ?? 'N';
                this.GetStyleSet(res.backurl);
                clearInterval(this.qrTime);
              } else {
                this.loginblock = true;
                this.loginco = res.detail + "，请重新输入";
                this.genVerifyCode();
              }
            });
          }, 2000);
        }
      });
    },

    // 更新二维码
    genVerifyCode() {
      Api.ajaxRequest("genVerifyCode").then((res) => {
        let {code, data} = res;
        if (code == "200") {
          this.assetsimages = res?.data?.pic ?? '';
          this.verifyType = res?.data?.type ?? '';
          this.puzzle = res?.data?.puzzle ?? '';
          this.sn = res?.data?.sn ?? '';
          this.locationY = res?.data?.locationY ?? '';
          this.loginStatus = '';
          if (!this.assetsimages) {
            this.loginblock = true;
            this.loginco = "验证码获取失败！";
          }
        }
      });
    },

    // 获取系统名字
    getSysName() {
      let urls = this.gup("syscode");
      this.urls = urls;
      let params = {
        systemId: urls,
      };
      Api.ajaxRequest("system/query/by/systemId", params).then((res) => {
        let {
          code,
          data
        } = res;
        if (code == "200") {
          if (res.data.systemName == null) {
            this.systemName = "统一用户中心";
          } else {
            this.systemName = res.data.systemName;
          }
        } else {
          this.systemName = "统一用户中心";
        }
      });
    },
    gup(name) {
      var regexS = "[\\?&]" + name + "=([^&#]*)"; //匹配name参数对
      var regex = new RegExp(regexS);
      var results = regex.exec(window.location.href); //过滤超链接
      if (results == null) {
        return "";
      } else {
        return results[1];
      }
    },

    WebSocketTest() {
      sessionStorage.setItem('changePsd', 'N');
      var ws,
          _this = this,
          url = 'ws://zjlw.songyang.gov.cn:8090/uc/websocket/notice/' + Api.getUid() + '/N';
      ;
      if (window.location.href.indexOf('https:') !== -1) {
        url = 'wss://zjlw.songyang.gov.cn:8095/uc/websocket/notice/' + Api.getUid() + '/N';
      }
      // var ws,
      // 		_this = this,
      // 		url = 'ws://192.168.20.3:8081/uc/websocket/notice/'+ Api.getUid() +'/N';;
      // if(window.location.href.indexOf('https:') !== -1){
      // 	url = 'wss://192.168.20.3:8081/uc/websocket/notice/'+ Api.getUid() +'/N';
      // }
      if (url != '' && url != null) {
        if ("WebSocket" in window) {
          // 打开一个 web socket
          ws = new WebSocket(url);
          ws.onopen = function () {
            // alert("已连接");
          };
          ws.onmessage = function (evt) {
            var received_msg = JSON.parse(evt.data);
            // _this.$notify({title: received_msg.subject, message: received_msg.content, type: 'warning'});
            sessionStorage.setItem('changePsd', 'Y');
            eventBus.$emit('changePsd', 'Y');
            ws.close();
          };
          ws.onclose = function () {
            // 关闭 websocket
            // alert("连接已关闭...");
          };
          this.wsInit = ws;
        } else {
          // 浏览器不支持 WebSocket
          alert("您的浏览器不支持 WebSocket!");
        }
      }
    },

    GetStyleSet(backurl) {
      Api.ajaxRequest('style/get/config', {}).then(res => {
        let {code, data} = res;
        if (code == '200') {
          localStorage.setItem("styleValObj_" + JSON.parse(data).custId, data);
          if (this.needChangePsd == 'N') {
            if (this.locationHref) {
              window.location.href = decodeURIComponent(this.locationHref);
            } else if (this.urls) {
              window.location.href = backurl;
            } else {
              this.$router.push({
                path: "/index",
              });
            }
          }
        }
      });
    }
  },
  created() {
    this.urls = this.$route.query.syscode;
    this.locationHref = this.gup("sysurl");
    this.auth_code = this.gup("auth_code")
  },
  mounted() {
    let that = this;

    // 获取cookie里的账号密码
    this.password = this.$cookies.get("psd");
    this.user = this.$cookies.get("name");

    if (that.auth_code != '') {
      let params = {
        passcode: that.auth_code,
        type: "zzdapp",
        syscode: that.urls,
      };
      Api.ajaxRequest("sslogin", params).then((res) => {
        let {
          code,
          data,
          detail
        } = res;
        if (code == "200") {
          that.$cookies.set("token", res.token);
          that.$cookies.set("uid", res.uid);
          this.needChangePsd = res?.others?.resetPwd ?? 'N';
          this.GetStyleSet(res.backurl);
        } else {
          that.loginblock = true;
          that.loginco = '登录异常请使用账号密码登录';
        }
      });
    } else {
      window.addEventListener("message", function (event) {
        // 这里的event.data 就是登录成功的信息
        // 数据格式：{ "code": "aaaa", "state": "bbbb" }
        let code = event.data.code;
        if (code == undefined) {
        } else {
          let params = {
            passcode: code,
            type: "zzd",
            syscode: that.urls,
          };
          Api.ajaxRequest("sslogin", params).then((res) => {
            let {
              code,
              data,
              detail
            } = res;
            if (code == "200") {
              that.$cookies.set("token", res.token);
              that.$cookies.set("uid", res.uid);
              this.needChangePsd = res?.others?.resetPwd ?? 'N';
              this.GetStyleSet(res.backurl);
            } else {
              that.loginblock = true;
              that.loginco = '登录异常请使用账号密码登录';
            }
          });
        }
      });
    }

    this.getSysName();
    this.genVerifyCode();
  },
};
</script>

<style scoped lang="less">
ul li {
  list-style: none;
}


/deep/ .block-knra17ci {
  display: none;
}

.container {
  width: 100vw;
  height: 100vh;
  background-image: url(../assets/images/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 140px;

  .jszc {
    font-size: 15px;
    color: #ffffff;

    a {
      color: #ffffff;
      text-decoration: none;
    }

    span {
      padding: 0 15px;
    }

  }

  .FixedImg {
    position: fixed;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
      height: auto;
    }
  }

  .logo {
    width: 50%;
    top: 13%;
    left: 50%;
    display: flex;
    justify-content: center;

    img {
      width: 84px;
      height: 88px;
      margin-right: 20px;
    }

    .logoTitle {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      font-size: 35px;
      color: #ffffff;

      > div {
        font-weight: 500;
        margin-bottom: 5px;
      }

      > p {
        font-weight: 200;
        font-size: 26px;
        line-height: 1.5em;
      }
    }
  }

  .sysName {
    top: 20%;
    left: 50%;
    color: #8d5c3a;
    font-size: 32px;
    letter-spacing: 2px;
  }

  .poetry {
    width: 87px;
    top: 50%;
    left: 5%;
  }

  .sun {
    width: 145px;
    top: 10%;
    right: 0%;
  }

  // 登录提示
  .loginTips {
    width: 364px;
    height: 34px;
    background-image: url(../assets/images/login/icon-loginTip.png);
    background-size: 100% 100%;
    color: #f08338;
    position: fixed;
    left: 52.5%;
    bottom: 30%;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;

    p {
      font-weight: bold;
      font-size: 28px;
      cursor: pointer;
    }

    span {
      font-size: 16px;
    }
  }

  .loginBox {
    background-image: url(../assets/images/login/icon-loginBg.png);
    background-size: 100% 100%;
    width: 687px;
    height: 618px;
    display: flex;
    padding: 50px;
    position: relative;

    .icon {
      width: 48px;
      height: 48px;
      position: absolute;
      z-index: 100;
      left: 70px;
      top: 72px;
      cursor: pointer;
    }

    .login-form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 40px 0;

      .title {
        font-size: 28px;
        color: #fff;
        font-weight: bold;
        letter-spacing: 4px;
      }

      // 账号登陆
      ul {
        width: 100%;

        li {
          width: 419px;
          height: 66px;
          background-image: url(../assets/images/login/icon-inputItem.png);
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          padding: 0 10px 0 30px;
          margin: 0 auto;

          img {
            width: 27px;
            height: 27px;
            margin-right: 10px;
          }

          input {
            flex: 1;
            height: 100%;
            background-color: transparent;
            padding-left: 15px;
            color: #fff;
            font-size: 16px;
            line-height: 60px;
          }

          input::-webkit-input-placeholder {
            color: #0a77e7;
          }

          .ewm-left {
            width: 13px;
            height: 34px;
            margin-right: -3px;
          }

          .ewm {
            width: 88px;
            height: auto;
            border-radius: 4px;
            cursor: pointer;
          }

          .text {
            width: 80%;
            height: 46px;
            text-align: center;
            color: #fff;
            line-height: 46px;
            font-size: 16px;
            cursor: pointer;
            border-radius: 4px;

            &:hover {
              background-color: #ccc;
            }
          }
        }

        li + li {
          margin-top: 30px;
        }
      }

      // 浙政钉登录
      div {
        width: 65%;
        height: 85%;
        overflow: hidden;

        iframe {
          width: 100%;
          height: 100%;
          border: 0px solid rgba(0, 0, 0, 0);
          background-color: #ff0 !important;
          animation: mymove 0.5s forwards;
          transform: scale(2);

          .node_k86xnenp {
            width: 300px;
          }
        }
      }

      .loginBtn {
        width: 483px;
        height: 71px;
        background-image: url(../assets/images/login/icon-loginBtn.png);
        background-size: 100% 100%;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        line-height: 71px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

@keyframes mymove {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 0;
  }

  60% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
