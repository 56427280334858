<template>
  <div class="head-container">
    <div class="headLeft" @click="jiheye" v-if="!hiddenList">
      <img src="../assets/images/top_logo.png">
      <div class="logoTitle">
        <div>5G场景“旅游+”智慧化服务技术研发与应用</div>
        <p>课题一沉浸式体验产品</p>
      </div>
    </div>
    <div class="headMidden" v-if="!hiddenList">
      <div class="middenItem" :class="{'meunAct' : item.id == menuId}" v-for="item in menuArr" :key="item.id"
           @click="menuGoto(item.pathUrl,item.id,item.child)"> {{ item.name }}
      </div>
    </div>
    <div class="headRight" v-if="!hiddenList">
      <span>{{ username }}</span>
      <span @click="logout">退出</span>
    </div>
    <div class="logincose" v-if="loginblock==true">
      <span>{{ loginco }}</span>
      <p @click="loginyab">确 定</p>
    </div>
  </div>
</template>

<script>
import Api from '@/server/index.js';

export default {
  name: 'headTop',
  props: ['menuId','hiddenList'],
  data() {
    return {
      loginblock: false,
      loginco: '',
      username: '',
      menuArr: []
    }
  },
  methods: {
    jiheye() {
      this.$router.push({
        path: '/index'
      })
    },
    logout() {
      Api.ajaxRequest('logout').then(res => {
        let {code, data} = res;
        if (code == '200') {

          localStorage.clear();
          let expireDate = new Date(); //获取时间
          expireDate.setTime(expireDate.getTime() + 100); //保存的天数
          window.document.cookie = "uid=null;path=/;expires=" + expireDate;
          window.document.cookie = "token=null;path=/;expires=" + expireDate;
          return false
          setTimeout(() => {
            this.$router.push({
              path: '/'
            })
          })

        } else {
          alert('退出失败')
        }
      });
    },
    menuGoto(path, id, child) {
      this.$router.push({path})
    },
    // 权限模块
    permission() {
      let params = {"systemId": 'uc'}
      Api.ajaxRequest('auth/query/permission', params).then(res => {
        let {code, data} = res;
        if (code == '200') {
          let list = data
          let arr = []
          list.forEach((item, index) => {
            arr.push({
              id: item.id,
              pathUrl: item.url,
              name: item.name,
              child: item.child,
            })
          })
          this.menuArr = arr;
        } else if (code == '503') {
          this.loginblock = true
          this.loginco = '登录信息已过期,请重新登录'
        } else {
          this.loginblock = true
          this.loginco = res.description
        }
      });
    },
    loginyab() {
      this.loginblock = false
      this.$router.push({
        path: '/'
      })
    },
    permissions() {
      let params = Api.formDataArg()
      Api.ajaxRequestas('user/get/info', params).then(res => {
        let {code, data} = res;
        if (code == '200') {
          this.username = data.realName
        }
      });
    },
  },
  mounted() {
    this.permission();
    this.permissions();
  }
}
</script>

<style scoped lang="less">
.head-container {
  width: 100%;
  height: 80px;
  background-image: url(../assets/images/topBg.png);
  background-size: 100% 100%;
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logincose {
    padding: 10px 0;
    width: 300px;
    height: 120px;
    background-color: #fff;
    border-radius: 10px;
    position: fixed;
    left: 8%;
    top: 10%;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 9999;
    border: 1px solid #ff0000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      background-color: #b99765;
      color: #fff;
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
      font-size: 16px;
    }

    span {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .headLeft {
    width: auto;
    height: 52px;
    display: flex;
    margin-right: 100px;
    cursor: pointer;

    img {
      width: auto;
      height: 100%;
    }

    .logoTitle {
      color: #fff;
      font-size: 20px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      > div {
        font-weight: bold;
      }
    }
  }

  .headMidden {
    flex: 1;
    display: flex;
    align-items: center;

    .middenItem {
      font-size: 16px;
      color: #fff;
      height: 40px;
      cursor: pointer;
      padding: 0 40px;
      white-space: nowrap;
    }

    .meunAct {
      background-image: url(../assets/images/icon_16.png);
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: 25px 16px;
    }
  }

  .headRight {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      font-size: 16px;
      line-height: 16px;
      color: #f5f7fa;
      cursor: pointer;
    }

    span + span {
      border-left: 1px solid #fff;
      margin-left: 15px;
      padding-left: 15px;
    }
  }
}
</style>
